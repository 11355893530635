<template>
  <p-nav id="navbar" class="d-none-mobile-app" :style="navbarStyles">
    <p-nav-logo :href="rootUrl" class="xl:!mr-8 !mr-4">
      <span class="sr-only">remove.bg</span>
      <p-logo-rbg />
    </p-nav-logo>
    <ul class="hidden xl:flex flex-grow items-center gap-1 xl:gap-1 my-0">
      <li>
        <Button variant="ghost" as="link" :href="newImageUrl">
          {{ I18n.t("navigation.remove_background") }}
        </Button>
      </li>
      <li>
        <p-dropdown vertical-offset="16">
          <template #trigger="{ active }">
            <Button :active="active" variant="ghost" as="link" href="#">
              {{ I18n.t("navigation.features") }}
              <template #end>
                <ChevronDownIcon />
              </template>
            </Button>
          </template>

          <template #content>
            <GridDropdown :menu-items="features" :sub-menu-items="featuresSubMenuItems" />
          </template>
        </p-dropdown>
      </li>
      <li>
        <p-dropdown vertical-offset="16">
          <template #trigger="{ active }">
            <Button :active="active" variant="ghost" as="link" href="#">
              {{ titleize(I18n.t("navigation.for_business")) }}
              <template #end>
                <ChevronDownIcon />
              </template>
            </Button>
          </template>

          <template #content>
            <GridDropdown :menu-items="targetGroups" :sub-menu-items="forBusinessSubMenuItems" />
          </template>
        </p-dropdown>
      </li>
      <li>
        <Button variant="ghost" as="link" :href="pricingUrl">
          {{ I18n.t("navigation.pricing") }}
          <p-badge v-if="blackFriday" class="ml-1" tone="primary">-40%</p-badge>
        </Button>
      </li>
    </ul>

    <ul class="hidden flex-none xl:flex items-center gap-2 xl:gap-2 my-0">
      <li v-if="!signedIn">
        <Button variant="ghost" as="link" :href="loginUrl">
          {{ I18n.t("navigation.login") }}
        </Button>
      </li>
      <li v-if="!signedIn">
        <p-button variant="secondary" :href="signupUrl" as="link">
          {{ I18n.t("navigation.signup") }}
        </p-button>
      </li>
      <div v-if="signedIn">
        <p-dropdown align-right :on-open="loadCredits" vertical-offset="16">
          <template #trigger>
            <p-avatar>{{ initals }}</p-avatar>
          </template>
          <template #content>
            <div class="!p-6 flex flex-col gap-y-4" style="width: 260px">
              <span class="text-typo-secondary font-bold">{{ displayName }}</span>
              <div class="flex flex-col gap-y-1">
                <span class="text-brand-typo font-bold text-2xl text-break">
                  {{ I18n.t("dashboard.credits", { count: credits }) }}
                </span>
                <p-link :href="pricingUrl" hide-arrow>{{ I18n.t("dashboard.buy_credits_or_subscribe") }}</p-link>
              </div>
              <p-nav-link height="sm" :href="accountUrl">{{ I18n.t("navigation.my_account") }}</p-nav-link>
              <p-nav-link height="sm" :href="logoutUrl" data-method="delete">{{
                I18n.t("navigation.logout")
              }}</p-nav-link>
            </div>
          </template>
        </p-dropdown>
      </div>
    </ul>

    <div class="flex flex-none xl:hidden items-center">
      <a :href="pricingUrl" class="no-underline">
        <p-badge v-if="blackFriday" class="ml-1 sm:block" tone="primary">-40%</p-badge>
      </a>
      <p-burger-menu :on-open="mobileOpenCallback" :on-close="mobileCloseCallback">
        <template #content>
          <ul class="flex flex-col divide-y divide-secondary">
            <li v-if="signedIn">
              <a :href="accountUrl" class="flex space-x-4 py-8 text-typo no-underline">
                <p-avatar class="flex-shrink-0">{{ initals }}</p-avatar>
                <span class="flex flex-col divide-none overflow-hidden justify-center">
                  <span v-if="displayName != email" class="font-bold">{{ displayName }}</span>
                  <span class="truncate flex-shrink-1">{{ email }}</span>
                </span>
              </a>
            </li>

            <li v-if="signedIn">
              <div class="py-8">
                <span class="text-brand-typo font-bold text-2xl">
                  {{ I18n.t("dashboard.credits", { count: credits }) }}
                </span>
                <p-link :href="pricingUrl" hide-arrow>{{ I18n.t("dashboard.buy_credits_or_subscribe") }}</p-link>
              </div>
            </li>

            <li>
              <p-nav-link :href="newImageUrl" height="lg">
                {{ I18n.t("navigation.remove_background") }}
              </p-nav-link>
            </li>
            <li>
              <p-accordion-group>
                <p-accordion no-horizontal-padding>
                  <template #title>
                    {{ I18n.t("navigation.features") }}
                  </template>

                  <template #content>
                    <div class="flex flex-column gap-2">
                      <TeaserCard
                        v-for="feature in features"
                        :key="feature.title"
                        :href="feature.href"
                        isCompact
                        :showArrow="false"
                        :imageUrl="feature.thumbnail_image"
                      >
                        <template #title>{{ feature.title }}</template>
                        <template #description
                          ><span>{{ feature.short_description }}</span></template
                        >
                      </TeaserCard>
                      <div class="flex-grow-1"><div class="border !border-secondary"></div></div>
                      <CardBase v-for="item in featuresSubMenuItems" variant="ghost" as="link" class="!p-3 group">
                        <Link
                          :href="item.href"
                          class="flex gap-2 underline-none !text-base"
                          :class="{ 'text-primary': item.isPrimary }"
                          size="lg"
                          :hide-arrow="!item.showArrow"
                        >
                          <component v-if="!!item.icon" :is="item.icon" />
                          {{ item.title }}
                        </Link>
                      </CardBase>
                    </div>
                  </template>
                </p-accordion>
              </p-accordion-group>
            </li>
            <li>
              <p-accordion-group>
                <p-accordion no-horizontal-padding>
                  <template #title>
                    {{ titleize(I18n.t("navigation.for_business")) }}
                  </template>

                  <template #content>
                    <div class="flex flex-column gap-2">
                      <TeaserCard
                        v-for="targetGroup in targetGroups"
                        :key="targetGroup.title"
                        :href="targetGroup.href"
                        isCompact
                        :showArrow="false"
                        :imageUrl="targetGroup.thumbnail_image"
                      >
                        <template #title>{{ targetGroup.title }}</template>
                        <template #description
                          ><span>{{ targetGroup.short_description }}</span></template
                        >
                      </TeaserCard>
                      <div class="flex-grow-1"><div class="border !border-secondary"></div></div>
                      <CardBase v-for="item in forBusinessSubMenuItems" variant="ghost" as="link" class="!p-3 group">
                        <Link
                          :href="item.href"
                          class="flex gap-2 underline-none !text-base"
                          :class="{ 'text-primary': item.isPrimary }"
                          size="lg"
                          :hide-arrow="!item.showArrow"
                        >
                          <component v-if="!!item.icon" :is="item.icon" />
                          {{ item.title }}
                        </Link>
                      </CardBase>
                    </div>
                  </template>
                </p-accordion>
              </p-accordion-group>
            </li>
            <li>
              <p-nav-link height="lg" :href="pricingUrl">
                {{ I18n.t("navigation.pricing") }}
                <p-badge v-if="blackFriday" class="ml-1" tone="primary">-40%</p-badge>
              </p-nav-link>
            </li>

            <li v-if="!signedIn">
              <p-nav-link height="lg" :href="loginUrl">{{ I18n.t("navigation.login") }}</p-nav-link>
            </li>
            <li v-if="signedIn">
              <p-nav-link height="lg" data-method="delete" :href="logoutUrl">{{
                I18n.t("navigation.logout")
              }}</p-nav-link>
            </li>
          </ul>
          <ul v-if="!signedIn" class="flex flex-col divide-y divide-secondary">
            <li class="w-full">
              <p-button v-if="!signedIn" as="link" :full-width="true" variant="secondary" :href="signupUrl"
                >{{ I18n.t("navigation.signup") }}
              </p-button>
            </li>
          </ul>
        </template>
      </p-burger-menu>
    </div>
  </p-nav>
</template>

<script setup>
import "@/src/i18n";
import { ref, inject, computed, onMounted, nextTick } from "vue";
import emitter from "@/modules/event_bus";
import {
  Button,
  ChevronDownIcon,
  ResourcesIcon,
  SettingsIcon,
  TeaserCard,
  InfoIcon,
  MessageSquareTextIcon,
  Link,
} from "prism";

import GridDropdown from "./dropdown.vue";

const mobileNavOpen = ref(false);
const credits = ref(0);
const loaded = ref(false);
const I18n = inject("I18n");

const props = defineProps({
  signedIn: Boolean,
  blackFriday: Boolean,
  initals: String,
  displayName: String,
  email: String,
  rootUrl: String,
  newImageUrl: String,
  creditsInfoUrl: String,
  pricingUrl: String,
  signupUrl: String,
  loginUrl: String,
  logoutUrl: String,
  accountUrl: String,
  toolsUrl: String,
  contactSalesUrl: String,
  featuresOverviewUrl: String,
  resourcesUrl: String,
  helpCenterUrl: String,
  targetGroups: Array,
  features: Array,
});

const mobileOpenCallback = () => {
  mobileNavOpen.value = true;
  document.body.classList.add("lock-scroll");
  loadCredits();
};

const mobileCloseCallback = () => {
  document.body.classList.remove("lock-scroll");
  mobileNavOpen.value = false;
};

const loadCredits = () => {
  if (loaded.value === true) return;
  if (!props.signedIn) return;

  fetch(props.creditsInfoUrl)
    .then((res) => res.json())
    .then((data) => {
      credits.value = data.credits;
      loaded.value = true;
    });
};

const featuresSubMenuItems = [
  {
    showArrow: true,
    title: I18n.t("navigation.explore_features"),
    href: props.featuresOverviewUrl,
    isPrimary: true,
  },
  {
    title: I18n.t("navigation.api_integrations"),
    icon: SettingsIcon,
    href: props.toolsUrl,
    isPrimary: false,
  },
  {
    title: I18n.t("footer.links.help_faq"),
    icon: InfoIcon,
    href: props.helpCenterUrl,
    isPrimary: false,
  },
];

const forBusinessSubMenuItems = [
  {
    title: I18n.t("support.contact_sales"),
    href: props.contactSalesUrl,
    icon: MessageSquareTextIcon,
    isPrimary: true,
  },
  {
    title: I18n.t("navigation.tools_api"),
    icon: SettingsIcon,
    href: props.toolsUrl,
    isPrimary: false,
  },
  {
    title: I18n.t("navigation.resources"),
    icon: ResourcesIcon,
    href: props.resourcesUrl,
    isPrimary: false,
  },
];

emitter.on("purchase", () => {
  loaded.value = false;
});

onMounted(() => {
  const maintenanceModeBanner = document.querySelector("#maintenanceModeBanner");
  maintenanceModeBannerVisible.value = !!maintenanceModeBanner;
});

const maintenanceModeBannerVisible = ref(false);
emitter.on("maintenanceModeBannerVisibilityChanged", (visible) => {
  maintenanceModeBannerVisible.value = visible;

  const toolsNav = document.querySelector("#tools-nav");
  if (!toolsNav) return;

  if (visible) {
    toolsNav.setAttribute("style", `padding-top: 1rem !important`);
  } else if (toolsNav) {
    toolsNav.setAttribute("style", `padding-top: 1rem !important`);
  }
});

const navbarStyles = computed(() => {
  const maintenanceModeBanner = document.querySelector("#maintenanceModeBanner");

  if (maintenanceModeBanner) {
    nextTick(() => {
      const pageContent = document.getElementById("page-content");
      pageContent.style.marginTop = `${maintenanceModeBanner.clientHeight + 72}px`;
    });
    return {
      top: maintenanceModeBannerVisible.value ? maintenanceModeBanner.clientHeight + "px" : "0",
    };
  }
});

const titleize = (str) => {
  return str.replace(/(^|\s)\S/g, (char) => char.toUpperCase());
};
</script>

<style>
.lock-scroll {
  overflow: hidden;
}
nav {
  transition: top 0.5s;
  transition: padding-top 0.5s;
}
</style>
