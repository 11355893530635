import { Background, ResultVariant, isPexelsBackground } from "@/modules/internal_api/image";
import { Store, defineStore } from "pinia";
import { DrawAction } from "@/stores/line";
import type { Line, Point } from "@/stores/line";
import {
  rbgEditorUndoActionV100,
  rbgEditorRedoActionV100,
  rbgEditorZoomImageV100,
  rbgEditorResetV100,
} from "kaleido-event-tracker";
import Flipper from "@/modules/flipper";

export type MagicBrushCacheData = {
  // This is a unique id for the iteration that led the result in this state
  // Will be used on the server to replay the same iteration for the HD result
  iterationId: string;
  // action: DrawAction;
};

const MIN_ZOOM = 100;
const MAX_ZOOM = 400;

export type ImageState = {
  previewVariant: ResultVariant;
  hdVariant: ResultVariant;
  lines: Line[];
  magicBrushCache?: MagicBrushCacheData;
  selectedBackgroundPhotoUrl: string | null;
  selectedBackgroundPhotoPhotographer?: string;
  selectedBackgroundPhotoSourceUrl?: string;
  uploadedBackgroundPhotoUrl: string | null;
  selectedBackgroundLoading: boolean;
  selectedSearchedBackground: boolean;
  selectedBackgroundColor: string | null;
  blurRadius: number | null;
  isBackgroundAddedByBlur: boolean;
  shadowOpacity: number;
  isBackgroundBlurEnabled: boolean;
  isShadowLayerVisible: boolean;
  noForegroundCtaActionTaken?: boolean;
};

export type HistoryImageState = {
  undoStack: ImageState[];
  redoStack: ImageState[];
  current: ImageState;
  zoomLevel: number;
  defaultZoomLevel: number;
};

export type ShadowConfiguration = {
  isShadowLayerVisible: boolean;
  shadowOpacity: number;
};

export function createPersistentStore(id: string) {
  // Ignore ts warning about no overload matches this call
  //@ts-ignore

  return defineStore(id, {
    state: (): HistoryImageState => ({
      undoStack: [],
      redoStack: [],
      current: {
        previewVariant: ResultVariant.Preview,
        hdVariant: ResultVariant.Hd,
        lines: [],
        magicBrushCache: undefined,
        selectedBackgroundPhotoUrl: null,
        selectedBackgroundLoading: false,
        selectedSearchedBackground: false,
        uploadedBackgroundPhotoUrl: null,
        selectedBackgroundColor: null,
        selectedBackgroundPhotoPhotographer: null,
        selectedBackgroundPhotoSourceUrl: null,
        shadowOpacity: 50,
        blurRadius: 10,
        isBackgroundAddedByBlur: false,
        isBackgroundBlurEnabled: false,
        isShadowLayerVisible: false,
        noForegroundCtaActionTaken: null,
      },
      defaultZoomLevel: -1,
      zoomLevel: MIN_ZOOM,
    }),
    getters: {
      canZoomOut(): boolean {
        return this.zoomLevel > MIN_ZOOM;
      },
      canZoomIn(): boolean {
        return this.zoomLevel < MAX_ZOOM;
      },
      imageId(): string {
        return id;
      },
      canUndo(): boolean {
        return this.undoStack.length > 0;
      },
      canRedo(): boolean {
        return this.redoStack.length > 0;
      },
      previewVariant(): ResultVariant {
        return this.current.previewVariant;
      },
      hdVariant(): ResultVariant {
        return this.current.hdVariant;
      },
      lines(): Line[] {
        return this.current.lines;
      },
      lastLine(): Line | null {
        if (this.current.lines.length > 0) {
          return this.current.lines[this.current.lines.length - 1];
        } else {
          return null;
        }
      },
      blurRadius(): number {
        return this.current.blurRadius;
      },
      isBackgroundAddedByBlur(): boolean {
        return this.current.isBackgroundAddedByBlur;
      },
      shadowOpacity(): number {
        return this.current.shadowOpacity;
      },
      isBackgroundBlurEnabled(): boolean {
        return this.current.isBackgroundBlurEnabled;
      },
      selectedBackgroundColor(): string | null {
        return this.current.selectedBackgroundColor;
      },
      selectedBackgroundPhotoUrl(): string | null {
        return this.current.selectedBackgroundPhotoUrl;
      },
      selectedBackgroundLoading(): boolean {
        return this.current.selectedBackgroundLoading;
      },
      selectedBackgroundAttribution(): { photographer: string; sourceUrl: string } | undefined {
        if (!this.current.selectedBackgroundPhotoPhotographer || !this.current.selectedBackgroundPhotoSourceUrl) {
          return undefined;
        }
        return {
          photographer: this.current.selectedBackgroundPhotoPhotographer,
          sourceUrl: this.current.selectedBackgroundPhotoSourceUrl,
        };
      },
      uploadedBackgroundPhotoUrl(): string | null {
        return this.current.uploadedBackgroundPhotoUrl;
      },
      magicBrushCache(): MagicBrushCacheData | undefined {
        return this.current.magicBrushCache;
      },
      isShadowLayerVisible(): boolean {
        return this.current.isShadowLayerVisible;
      },
      noForegroundCtaActionTaken(): boolean {
        return this.current.noForegroundCtaActionTaken;
      },
      isSelectedSearchedBackground(): boolean {
        return this.current.selectedSearchedBackground;
      },
      lastSelectedBackgroundPhoto(): Background | null {
        return this.current.lastSelectedBackgroundPhoto;
      },
    } as StoreGetters,
    actions: {
      zoomIn(incrementBy: number = 20): void {
        this.setZoom(this.zoomLevel + incrementBy);
        if (Flipper.isEnabled("zoom_events")) {
          rbgEditorZoomImageV100({ image_id: id, mode: "increase", percentage_value: this.zoomLevel });
        }
      },
      zoomOut(decrementBy: number = 20): void {
        this.setZoom(this.zoomLevel - decrementBy);
        if (Flipper.isEnabled("zoom_events")) {
          rbgEditorZoomImageV100({ image_id: id, mode: "decrease", percentage_value: this.zoomLevel });
        }
      },
      setZoom(zoomLevel): void {
        const clampedZoomLevel = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, Math.round(zoomLevel)));

        this.zoomLevel = clampedZoomLevel;
      },
      setDefaultZoom(zoomLevel): void {
        this.defaultZoomLevel = zoomLevel;
      },
      snapshot(): void {
        // console.log("creating history snapshot");
        // deep clone current state and push it to the undo stack
        const clonedState = JSON.parse(JSON.stringify(this.current));
        this.undoStack.push(clonedState);
        // clear the redo stack
        this.redoStack = [];
      },

      snapshotWithState(state: ImageState): void {
        // console.log("creating history snapshot with state");
        // deep clone current state and push it to the undo stack
        const clonedState = JSON.parse(JSON.stringify(state));
        this.undoStack.push(clonedState);
        // clear the redo stack
        this.redoStack = [];
      },

      persist(): void {
        this.$persist();
      },

      withSnapshot<T>(fn: () => T): void {
        this.snapshot();
        fn();
        this.persist();
      },

      undo(): void {
        if (this.canUndo) {
          const newCurrent = this.undoStack.pop()!;
          this.redoStack.push(this.current);
          this.current = newCurrent;
          this.$persist();

          window.track("Editor", "undo", "Undo");
          rbgEditorUndoActionV100({ image_id: id });
        }
      },

      redo(): void {
        if (this.canRedo) {
          const newCurrent = this.redoStack.pop()!;
          this.undoStack.push(this.current);
          this.current = newCurrent;
          this.$persist();

          window.track("Editor", "redo", "Redo");
          rbgEditorRedoActionV100({ image_id: id });
        }
      },

      // Reset the image to its inital state
      reset(): void {
        // If the undo stack is not emptt it means that the original state is stored there
        // otherwise the inital state is the current state
        let newCurrent = this.current;
        if (this.canUndo) {
          newCurrent = this.undoStack[0];
        }
        this.undoStack = [];
        this.redoStack = [];
        this.current = newCurrent;
        this.$persist();
        rbgEditorResetV100({ image_id: id });
      },

      cacheMagicBrush(data: MagicBrushCacheData): void {
        this.current.magicBrushCache = { ...this.current.magicBrushCache, ...data };
      },

      selectPreviewVariant(): void {
        this.current.previewVariant = ResultVariant.Preview;
        this.current.hdVariant = ResultVariant.Hd;
      },

      selectMagicBrushVariant(): void {
        this.current.previewVariant = ResultVariant.MagicBrushPreview;
        this.current.hdVariant = ResultVariant.MagicBrushHd;
      },

      startLine(point: Point, action: DrawAction, brushSize: number): void {
        this.current.lines.push({ points: [point], action, brushSize });
      },

      // Add a point to the last/current line
      addLinePoint(point: Point): void {
        const lastLine = this.lastLine;
        if (lastLine) {
          lastLine.points.push(point);
        }
        this.current.lines.splice(this.current.lines.length - 1, 1, lastLine);
      },

      clearLines(): void {
        this.current.lines = [];
      },

      setBlurRadius(radius: number): void {
        this.current.blurRadius = radius;
      },
      setIsBackgroundAddedByBlur(value: boolean): void {
        this.current.isBackgroundAddedByBlur = value;
      },
      setShadowOpacity(opacity: number): void {
        this.current.shadowOpacity = opacity;
      },
      setBackgroundBlurEnabled(enabled: boolean): void {
        this.current.isBackgroundBlurEnabled = enabled;
      },

      setSelectedBackgroundLoading(active: boolean): void {
        this.current.selectedBackgroundLoading = active;
      },

      setSelectedBackgroundColor(color: string): void {
        this.current.selectedBackgroundColor = color;
      },
      setSelectedBackgroundPhoto(photo?: Background): void {
        if (!photo) {
          this.current.selectedBackgroundPhotoUrl = null;
          this.current.selectedBackgroundPhotoPhotographer = null;
          this.current.selectedBackgroundPhotoSourceUrl = null;
          return;
        }
        this.current.selectedBackgroundPhotoUrl = photo.image_url;
        this.current.selectedBackgroundPhotoPhotographer = photo.photographer;
        this.current.selectedBackgroundPhotoSourceUrl = photo.url;
        if (isPexelsBackground(photo)) {
          this.current.lastSelectedBackgroundPhoto = photo;
        }
      },

      setUploadedBackgroundPhotoUrl(url: string): void {
        this.current.uploadedBackgroundPhotoUrl = url;
      },

      setShadowLayerVisible(visible: boolean): void {
        this.current.isShadowLayerVisible = visible;
      },
      setNoForegroundCtaActionTaken(action_taken: boolean): void {
        this.current.noForegroundCtaActionTaken = action_taken;
        this.$persist();
      },
      setupShadow(config: ShadowConfiguration) {
        this.current.shadowOpacity = config.shadowOpacity;
        this.current.isShadowLayerVisible = config.isShadowLayerVisible;
        this.$persist();
      },
      setSelectedSearchedBackground(value: boolean): void {
        this.current.selectedSearchedBackground = value;
      },
    } as StoreActions,
    persist: {
      key: `image-${id}`,
    },
  });
}

// Due to dynamic manner of createPersistentStore function it's hard to infer proper typing later in the code. Manual types are not ideal solution but currently the best one.
export type PersistentStore = Store<string, HistoryImageState, StoreGetters, StoreActions>;

type StoreGetters = {
  canZoomOut: () => boolean;
  canZoomIn: () => boolean;
  imageId: () => string;
  canUndo: () => boolean;
  canRedo: () => boolean;
  previewVariant: () => ResultVariant;
  hdVariant: () => ResultVariant;
  lines: () => Line[];
  lastLine: () => Line | null;
  blurRadius: () => number;
  isBackgroundBlurEnabled: () => boolean;
  isBackgroundAddedByBlur: () => boolean;
  selectedBackgroundColor: () => string | null;
  selectedBackgroundPhotoUrl: () => string | null;
  selectedBackgroundLoading: () => boolean;
  uploadedBackgroundPhotoUrl: () => string | null;
  magicBrushCache: () => MagicBrushCacheData | undefined;
  isShadowLayerVisible: () => boolean;
  noForegroundCtaActionTaken: () => boolean;
  isSelectedSearchedBackground: () => boolean;
  selectedBackgroundAttribution: () => { photographer: string; sourceUrl: string } | undefined;
  lastSelectedBackgroundPhoto: () => Background | null;
};

type StoreActions = {
  zoomIn: (incrementBy?: number) => void;
  zoomOut: (decrementBy?: number) => void;
  setZoom: (zoomLevel: number) => void;
  snapshot: () => void;
  snapshotWithState: (state: ImageState) => void;
  persist: () => void;
  withSnapshot: <T>(fn: () => T) => void;
  undo: () => void;
  redo: () => void;
  reset: () => void;
  cacheMagicBrush: (data: MagicBrushCacheData) => void;
  selectPreviewVariant: () => void;
  selectMagicBrushVariant: () => void;
  startLine: (point: Point, action: DrawAction, brushSize: number) => void;
  addLinePoint: (point: Point) => void;
  clearLines: () => void;
  setBlurRadius: (radius: number) => void;
  setIsBackgroundAddedByBlur: (value: boolean) => void;
  setBackgroundBlurEnabled: (enabled: boolean) => void;
  setSelectedBackgroundColor: (color: string) => void;
  setUploadedBackgroundPhotoUrl: (url: string) => void;
  setSelectedBackgroundPhoto: (photo: Background) => void;
  setShadowLayerVisible: (visible: boolean) => void;
  setNoForegroundCtaActionTaken: (action_taken: boolean) => void;
  setSelectedBackgroundLoading: (active: boolean) => void;
  setupShadow: (config: ShadowConfiguration) => void;
  setSelectedSearchedBackground: (value: boolean) => void;
};
