<template>
  <!-- TODO: This dialog should be aligned with logic flow simmilar in desktop modal: first it should check if user is logged in, then if image is processed -->
  <Dialog
    v-if="store?.selectedImage?.hdResult?.state !== ProcessingState.Finished"
    ref="dialog"
    :on-close="closeDialog"
    :on-open="onDialogOpen"
    trigger-classes="flex justify-center sm:justify-start focus:outline-none"
    :dismissable="!isIosDisabled || !User.signedIn()"
  >
    <template #trigger>
      <div class="flex flex-col gap-0.5 sm:gap-1.5 sm:pr-1.5 align-items-center w-full" v-if="props.showButton">
        <ActionButton class="sm:!flex-col" tone="secondary" :loading="props.isProcessingHdDownload" @click="() => {}">
          <template #icon>
            <DownloadIcon />
          </template>
        </ActionButton>
        <span class="text-2xs sm:text-sm text-center whitespace-nowrap text-ellipsis overflow-hidden w-full">
          <span
            class="font-bold text-3xs sm:text-sm w-full text-center sm:text-left transition ease-in-out group-hover:!text-typo-secondary sm:no-touch-hover:group-hover:translate-x-0.5"
            >{{ I18n.t("image.download_high_res") }}</span
          >
        </span>
      </div>

      <div class="flex gap-1 items-start justify-center cursor-wait" v-else>
        <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
      </div>
    </template>

    <template v-if="User.signedIn()" v-slot:content="{ closeDialog }">
      <template v-if="props.isIosDisabled">
        <Ios16MPDialogContent :close-dialog="closeDialog" />
      </template>
      <template v-else>
        <div class="flex flex-col gap-y-6 w-full">
          <div class="flex flex-col gap-y-0 mt-3">
            <Heading level="h4">{{ I18n.t("image.download_high_res") }}</Heading>
            <p class="!m-0 text-xl text-brand-typo font-bold">
              HD {{ store.selectedImage.meta.hdWidth }}x{{ store.selectedImage.meta.hdHeight }}px
            </p>
          </div>
          <!-- Image preview section -->
          <div class="image-preview" style="height: auto; width: auto">
            <div class="flex justify-center" v-if="isPreviewLoading">
              <div class="rounded !bg-secondary !w-full animate-pulse" style="height: 187px"></div>
            </div>
            <div v-else class="sm:w-128 rounded-lg !bg-secondary border !border-secondary">
              <HTMLImage
                v-if="store.selectedImage.meta.orientation === ImageOrientation.Portrait"
                :src="getPreview()"
                alt=""
                width="200"
                :fluid="false"
                img-classes="bg-white checkerboard rounded-lg mx-auto"
              />
              <HTMLImage
                v-else
                :src="getPreview()"
                alt=""
                :fluid="false"
                img-classes="!w-full bg-white checkerboard rounded-lg mx-auto"
              />
            </div>
          </div>

          <div class="flex flex-col gap-y-3 justify-center">
            <div class="text-center">
              <Button
                class="mb-2 !text-base"
                size="lg"
                type="submit"
                :loading="props.isProcessingHdDownload"
                :disabled="props.credits <= 0 || !isFullHdAvailable"
                @click.prevent="() => props.downloadHd(closeDialog)"
                >{{ I18n.t("image.download_high_res_for_1_credit") }}</Button
              >
            </div>
            <div class="text-center">
              <span v-if="!isFullHdAvailable" class="text-typo-tertiary">
                {{ I18n.t("image.not_available") }}
                <span
                  class="hover-tooltip"
                  :data-tippy-content="`${I18n.t('image.please_upload_higher_resolution')}`"
                  data-tippy-placement="bottom"
                >
                  <InfoSmallIcon />
                </span>
              </span>
            </div>
            <div class="flex justify-center items-center space-x-4">
              <p class="!m-0 text-typo">
                {{ I18n.t("full_image.you_have_credits", { credits: props.credits }) }}
              </p>
              <a class="" :target="User.mobileApp() ? '_blank' : '_self'" :href="Routes.get('pricing_url')">{{
                I18n.t("full_image.get_credits")
              }}</a>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else v-slot:content="{ closeDialog }">
      <div class="flex flex-col gap-y-6 w-full">
        <div class="flex flex-col gap-y-0 mt-3">
          <Heading level="h4">{{ I18n.t("image.download_high_res_image") }}</Heading>
          <p class="!m-0 text-xl text-brand-typo font-bold">
            HD {{ store.selectedImage.meta.hdWidth }}x{{ store.selectedImage.meta.hdHeight }}px
          </p>
        </div>

        <div class="image-preview" style="height: auto; width: auto">
          <div class="flex justify-center" v-if="isPreviewLoading">
            <div class="rounded !bg-secondary !w-full animate-pulse" style="height: 187px"></div>
          </div>
          <div v-else class="sm:w-128 rounded-lg !bg-secondary border !border-secondary">
            <HTMLImage
              v-if="store.selectedImage.meta.orientation === ImageOrientation.Portrait"
              :src="getPreview()"
              alt=""
              width="200"
              :fluid="false"
              img-classes="bg-white checkerboard rounded-lg mx-auto"
            />
            <HTMLImage
              v-else
              :src="getPreview()"
              alt=""
              :fluid="false"
              img-classes="!w-full bg-white checkerboard rounded-lg mx-auto"
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-3 justify-center">
          <p class="!m-0 text-xl text-typo font-bold text-center">{{ I18n.t("full_image.get_this_free") }}:</p>
          <div class="text-center">
            <span onclick="window.track('Images', 'hd_download_signup', 'Signup for Account from HD Download')">
              <Button as="link" :href="signupUrl()" size="lg" autofocus>{{ I18n.t("full_image.sign_up_free") }}</Button>
            </span>
          </div>
          <div class="flex gap-2 justify-center pb-4">
            <span>{{ I18n.t("full_image.already_have_account") }}</span>
            <Link hideArrow :href="loginUrl()">{{ I18n.t("full_image.login") }}</Link>
          </div>
        </div>
      </div>
    </template>

    <template #mobile-actions>
      <Button @click="closeDialog">{{ I18n.t("editor.modal_close") }}</Button>
    </template>
  </Dialog>
  <Dialog
    v-else-if="props.isIosDisabled"
    :dismissable="false"
    :on-close="closeDialog"
    :on-open="onDialogOpen"
    trigger-classes="flex justify-center sm:justify-start focus:outline-none"
  >
    <template #trigger>
      <div class="flex flex-col gap-0.5 sm:gap-1.5 sm:pr-1.5 align-items-center w-full" v-if="props.showButton">
        <ActionButton class="sm:!flex-col" tone="secondary" :loading="props.isProcessingHdDownload" @click="() => {}">
          <template #icon>
            <DownloadIcon />
          </template>
        </ActionButton>
        <span class="text-2xs sm:text-sm text-center whitespace-nowrap text-ellipsis overflow-hidden w-full">
          <span
            class="font-bold text-3xs sm:text-sm w-full text-center sm:text-left transition ease-in-out group-hover:!text-typo-secondary sm:no-touch-hover:group-hover:translate-x-0.5"
            >{{ I18n.t("image.download_high_res") }}</span
          >
        </span>
      </div>

      <div class="flex gap-1 items-start justify-center cursor-wait" v-else>
        <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
      </div>
    </template>
    <template v-slot:content="{ closeDialog }">
      <Ios16MPDialogContent :close-dialog="closeDialog" />
    </template>
  </Dialog>
  <template v-else>
    <div v-if="props.showButton" class="flex flex-col gap-0.5 sm:gap-1.5 sm:pr-1.5 align-items-center">
      <ActionButton
        class="sm:!flex-col"
        tone="secondary"
        :loading="props.isProcessingHdDownload"
        @click.prevent="() => props.downloadHd()"
      >
        <template #icon>
          <DownloadIcon />
        </template>
      </ActionButton>
      <span class="text-2xs sm:text-sm text-center whitespace-nowrap text-ellipsis overflow-hidden w-full">
        <span
          class="font-bold text-3xs sm:text-sm w-full text-center sm:text-left transition ease-in-out group-hover:!text-typo-secondary sm:no-touch-hover:group-hover:translate-x-0.5"
          >{{ I18n.t("image.download_high_res") }}</span
        >
      </span>
    </div>

    <div v-else class="flex gap-1 items-start justify-center cursor-wait">
      <div class="h-11 w-11 rounded-full !bg-secondary animate-pulse"></div>
    </div>
  </template>
</template>

<script setup lang="ts">
import "@/src/i18n";

import { Dialog, Button, DownloadIcon, Image as HTMLImage, Link, ActionButton, InfoSmallIcon } from "prism";

import User from "@/modules/user";
import Routes from "@/modules/routes";
import { Image, ImageOrientation, ProcessingState } from "@/modules/internal_api/image";
import Ios16MPDialogContent from "@/components/upload/ios_16mp_dialog_content.vue";

import { useEditorStore } from "@/stores/editor_store";
import { computed, inject } from "vue";

const store = useEditorStore();

interface MobileHdDownloadProps {
  editor: any;
  credits: any;
  downloadHd: Function;
  name: string;
  showButton: boolean;
  isProcessingHdDownload: boolean;
  isIosDisabled: boolean;
  loadCreditsAndUpdatePreview: Function;
}

const props = defineProps<MobileHdDownloadProps>();

const I18n = inject("I18n");

const isFullHdAvailable = computed(() => {
  return store.selectedImage.meta.fullAvailable;
});

const onDialogOpen = () => {
  if (User.signedIn()) {
    props.loadCreditsAndUpdatePreview();
  }
};

const signupUrl = () => {
  if (User.mobileApp()) {
    return "removebg://login";
  }

  return Routes.get("signup_url");
};

const loginUrl = () => {
  if (User.mobileApp()) {
    return "removebg://login";
  }

  return Routes.get("login_url");
};

// Image Preview

/**
 * isPreviewLoading is a computed boolean that determines whether or not the image preview
 *  for the HD download is ready.
 *  It's set to true only after the livePreview of selectedImage is updated to the latest.
 *  Or if the livePreview needs a refresh after some editing.
 */
const isPreviewLoading = computed(() => {
  // We do not want to show a loader when the download is being processed.
  if (props.isProcessingHdDownload) {
    return false;
  }

  const isPreviewRefreshing = store.selectedImage.meta.refreshingLivePreview === true; // values could be nil, it doesn't mean it's refreshing.
  return isPreviewSameAsOriginal(store.selectedImage) || isPreviewRefreshing;
});
const isPreviewSameAsOriginal = (image: Image): boolean => {
  return image.meta.livePreview === image.original?.url;
};

const getPreview = () => {
  return store.selectedImage.meta.livePreview;
};

const emit = defineEmits<{
  (e: "closeDialog"): void;
  (e: "downloadHd"): void;
}>();

const closeDialog = () => {
  emit("closeDialog");
};
</script>
