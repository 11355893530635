import { computed, ref } from "vue";
import loadSuggestions from "@/src/image_suggestions";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorBackgroundPexelsSearchedV100 } from "kaleido-event-tracker";
import Client from "@/modules/internal_api/client";
import {
  BackgroundImageCollection,
  BackgroundImageSearchError,
  BackgroundImageSearchErrorState,
} from "@/modules/internal_api/background_search";
import { Background } from "@/modules/internal_api/image";
import { withExponentialBackoff } from "@/modules/utils";

export function useBackgroundSearch(foregroundType: string) {
  const isRelevantBackgroundsLoading = ref(false);
  const areBackgroundsLoading = ref(false);
  const isCollectionsLoading = ref(false);
  const error = ref(null);

  const store = useEditorStore();
  const availableBackgrounds = ref<Background[]>([]);
  const collections = ref<BackgroundImageCollection[]>(store.collections);

  const loadRelevantBackgrounds = async () => {
    isRelevantBackgroundsLoading.value = true;
    try {
      const relevantBackgrounds = await loadSuggestions(foregroundType);
      availableBackgrounds.value = relevantBackgrounds;
    } catch (err) {
      handleErrors(err);
    } finally {
      isRelevantBackgroundsLoading.value = false;
    }
  };

  const loadRelevantCollections = async () => {
    isCollectionsLoading.value = true;
    try {
      const all = await fetchCollections();
      const filteredCollections = all
        .filter((collection) => collection.title.split(".")[0] === foregroundType)
        .map((collection) => {
          return {
            id: collection.id,
            title: collection.title.split(".")[1],
            key: collection.title,
          };
        });
      collections.value = filteredCollections;
      store.loadCollections(filteredCollections);
    } catch (err) {
      handleErrors(err);
    } finally {
      isCollectionsLoading.value = false;
    }
  };

  const fetchBackgrounds = async (searchTerm: string, page: number) => {
    try {
      areBackgroundsLoading.value = true;
      const backgrounds = await withExponentialBackoff(() => Client.fetchBackgrounds({ searchTerm, page }), {
        on: [BackgroundImageSearchError],
        retryLimit: 1,
      });
      error.value = null;
      return backgrounds;
    } catch (err) {
      handleErrors(err);
    } finally {
      rbgEditorBackgroundPexelsSearchedV100({ image_id: store.selectedImage.meta.id, search_text: "" });
      areBackgroundsLoading.value = false;
    }
  };

  const fetchCollections = async () => {
    try {
      const collections = await withExponentialBackoff(() => Client.fetchBackgroundCollections(), {
        on: [BackgroundImageSearchError],
        retryLimit: 1,
      });
      error.value = null;
      return collections;
    } catch (err) {
      handleErrors(err);
    }
  };

  const fetchCollectionBackgrounds = async (collection, page: number) => {
    try {
      areBackgroundsLoading.value = true;
      const backgrounds = await withExponentialBackoff(
        () => Client.fetchBackgroundsByCollection({ collection: collection.id, page }),
        {
          on: [BackgroundImageSearchError],
          retryLimit: 1,
        }
      );
      error.value = null;
      return backgrounds;
    } catch (err) {
      handleErrors(err);
    } finally {
      areBackgroundsLoading.value = false;
    }
  };

  const handleErrors = (err: BackgroundImageSearchError | any) => {
    if (err instanceof BackgroundImageSearchError) {
      error.value = err;
      return;
    }

    error.value = new BackgroundImageSearchError({
      state: BackgroundImageSearchErrorState.TryAgain,
      message: err.message.toString(),
      code: err.code,
    });
  };

  const isLoading = computed(
    () => isRelevantBackgroundsLoading.value || isCollectionsLoading.value || areBackgroundsLoading.value
  );

  return {
    isLoading,
    error,
    availableBackgrounds,
    collections,
    loadRelevantBackgrounds,
    loadRelevantCollections,
    fetchBackgrounds,
    fetchCollections,
    fetchCollectionBackgrounds,
  };
}
