import { Background, BackgroundImageOrigin } from "./image";

export interface BackgroundImageSrc {
  original: string;
  large2x: string;
  large: string;
  medium: string;
  small: string;
  portrait: string;
  landscape: string;
  tiny: string;
}

export interface BackgroundImageSearchResult {
  height: number;
  width: number;
  photographer: string;
  photographer_url: string;
  avg_color: string;
  url: string;
  src: BackgroundImageSrc;
}

export enum BackgroundImageSearchErrorState {
  NoResults = "no_results",
  TryAgain = "something_went_wrong",
  FeatureUnavailable = "feature_unavailable",
}

export interface BackgroundImageCollection {
  id: string;
  title: string;
  key?: string;
}

export class BackgroundImageSearchError extends Error {
  code: number;
  message: string;
  state: BackgroundImageSearchErrorState;

  constructor({ code, message, state }: { code: number; message: string; state: BackgroundImageSearchErrorState }) {
    super(message);
    this.name = "BackgroundImageSearchError";
    this.code = code;
    this.message = message;
    this.state = state;
  }
}

export function parseBackgroundImageSearchResult(
  backgrounds: BackgroundImageSearchResult[],
  isMobile?: boolean,
  origin?: BackgroundImageOrigin
): Background[] {
  return backgrounds.map(({ src, avg_color, url, photographer }) => ({
    id: src.original,
    thumbnail_url: src.small,
    // Use large2x for mobile, original for desktop - fix to prevent blur crashing on larger resolutions
    image_url: isMobile ? src.large2x : src.original,
    photographer,
    url,
    avg_color,
    image_origin: origin || BackgroundImageOrigin.Preset,
  }));
}

export function errorStateFromHttpStatus(statusCode: number): BackgroundImageSearchErrorState {
  switch (statusCode) {
    case 400:
      return BackgroundImageSearchErrorState.TryAgain;
    case 404:
      return BackgroundImageSearchErrorState.NoResults;
    case 503:
      return BackgroundImageSearchErrorState.FeatureUnavailable;
    default:
      return BackgroundImageSearchErrorState.TryAgain;
  }
}
