// needed for trustcomponent
declare global {
  interface Window {
    track: (category: string, action: string, label: string, item?: TrackingItem, transaction_id?: string) => void;
    reportSplit: (experiment: string, goal?: string | null, reset?: boolean) => void;
    submitChallenge: any;
    request_hcaptcha_checkbox: any;
    request_hcaptcha_invisible: any;
    error_tracking: any;
    useToken: any;
  }
}
import { TrackingItem } from "@/src/track";
import type { Image } from "@/modules/internal_api/image";
import originalPlaceholder from "@assets/images/inline-editor/original_placeholder.jpg";
import { rbgNativeShareSuccessfulV100, rbgNativeShareAbortedV100 } from "kaleido-event-tracker";

// Get nested key from object, e.g. getNestedKey({a: {b: 1}}, "a.b") => 1
export function getNestedKey(obj: any, key: string) {
  const keys = key.split(".");
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    current = current[keys[i]];
  }
  return current;
}

// This function could potentitally be used as a generic method to update local state with new values
// only if the server has them ready. The main purpose is to avoid unnecessary re-renderes and a smooth
// transition to the server state once it is ready.
export function updateIfDifferent<T>(existing: T, updates: Partial<T>, keysToCheck: (keyof T)[]): T {
  Object.keys(updates).forEach((key) => {
    const updateKey = key as keyof T;

    if (!keysToCheck.includes(updateKey)) {
      return;
    }

    const newValue = updates[updateKey];
    const oldValue = existing[updateKey];

    if (newValue !== null && newValue !== undefined && newValue !== oldValue) {
      console.log("updateIfDifferent", updateKey, newValue);
      existing[updateKey] = newValue;
    }
  });

  return existing;
}

export type PreviewImage = {
  image: string;
  width: number;
  height: number;
  original: {
    width: number;
    height: number;
    sizeMb: number;
  };
};

// Generate preview (base64 string) from file, can be used as src for <img>
export async function generatePreviewFromFile(file: File): Promise<PreviewImage> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const result = reader.result as string;
      const dimensions = await getResizedImageDimensions(result);
      const originalDimensions = await getImageDimensions(result);

      resolve({
        image: result,
        ...dimensions,
        original: {
          ...originalDimensions,
          sizeMb: file.size / (1024 * 1024),
        },
      });
    };
    reader.onerror = (_error) => resolve(defaultPlaceholder());
    reader.readAsDataURL(file);
  });
}

// Generate preview (base64 string) from URL, can be used as src for <img>
export async function generatePreviewFromUrl(url: string): Promise<PreviewImage> {
  return new Promise(async (resolve, reject) => {
    let response: Response;
    try {
      response = await fetch(url);
    } catch (_error) {
      // Could not fetch image, most likely an incorrect URL
      // or CORS is preventing us from fetching the image
      resolve(defaultPlaceholder());
    }

    if (response?.ok) {
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = async () => {
        const result = reader.result as string;
        const dimensions = await getResizedImageDimensions(result);
        const originalDimensions = await getImageDimensions(result);
        resolve({
          image: result,
          ...dimensions,
          original: {
            ...originalDimensions,
            sizeMb: blob.size / (1024 * 1024),
          },
        });
      };
      reader.onerror = (_error) => resolve(defaultPlaceholder());
      reader.readAsDataURL(blob);
    } else {
      // Could not fetch image, most likely an incorrect URL
      // or CORS is preventing us from fetching the image
      resolve(defaultPlaceholder());
    }
  });
}

function defaultPlaceholder(): PreviewImage {
  return {
    image: originalPlaceholder,
    width: 614,
    height: 408,
    original: {
      width: 0,
      height: 0,
      sizeMb: 0,
    },
  };
}

async function getResizedImageDimensions(
  dataUri: string,
  maxArea = 0.25 * 1000000
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, _reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      let { width, height } = img;
      const area = width * height;
      if (area > maxArea) {
        const ratio = Math.sqrt(maxArea / area);
        width = Math.round(width * ratio);
        height = Math.round(height * ratio);
      }

      resolve({ width, height });
    };
    img.onerror = (_error) => resolve({ width: 0, height: 0 });
    img.src = dataUri;
  });
}

async function getImageDimensions(dataUri: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, _reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = (_error) => resolve({ width: 0, height: 0 });
    img.src = dataUri;
  });
}

export async function downloadWithShareSheet(
  image: Image,
  uri: string,
  name: string,
  resolution: "standard" | "high-definition"
): Promise<void> {
  if (!navigator.share) return;

  const response = await fetch(uri);
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });

  try {
    await navigator.share({
      files: [file],
    });
    rbgNativeShareSuccessfulV100({ image_id: image.meta.id, resolution: resolution });
  } catch (error) {
    if (error.name === "AbortError") {
      rbgNativeShareAbortedV100({ image_id: image.meta.id, resolution: resolution });
    } else {
      // Something went wrong during sharing, most likely transient activation
      // so we fall back to the downloadURI method
      // https://developer.mozilla.org/en-US/docs/Glossary/Transient_activation
      downloadURI(uri, name);
    }
  }
}

function convertBase64ToBlob(base64Image: string) {
  // Split into two parts
  const parts = base64Image.split(";base64,");
  // Hold the content type
  const imageType = parts[0].split(":")[1];
  // Decode Base64 string
  const decodedData = window.atob(parts[1]);
  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);
  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
}

export async function downloadURI(uri: string, name: string): Promise<void> {
  let blob: Blob;
  // In firefox there seems to be limit for url length, to make sure it works across every platform convertBase64ToBlob is manual fallback that should always work
  try {
    const response = await fetch(uri);
    blob = await response.blob();
  } catch {
    blob = convertBase64ToBlob(uri);
  }

  const blobURL = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobURL;
  link.download = name;
  link.target = "_blank";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobURL); // Free up memory
}

export async function fileFromUri(uri: string, name: string): Promise<File> {
  let response = await fetch(uri);
  let data = await response.blob();
  return new Promise((resolve, _reject) => {
    let metadata = {
      type: "image/png",
    };
    const file = new File([data], name, metadata);
    resolve(file);
  });
}

export function downloadBlob(blob: Blob, name: string): void {
  const url = URL.createObjectURL(blob);
  downloadURI(url, name);
}

export function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export function urlToBlob(url: string): Promise<Blob> {
  return fetch(url).then((res) => res.blob());
}

export function releaseCanvas(canvas: HTMLCanvasElement | undefined): void {
  if (!canvas) {
    return;
  }
  canvas.width = 0;
  canvas.height = 0;
  const ctx = canvas.getContext("2d");
  ctx && ctx.clearRect(0, 0, 1, 1);
}

export function fetchImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });
}

export function calculateCrop(image, size, clipPosition = "left-top") {
  const width = size.width;
  const height = size.height;
  const aspectRatio = width / height;

  let newWidth;
  let newHeight;

  const imageRatio = image.width / image.height;

  if (aspectRatio >= imageRatio) {
    newWidth = image.width;
    newHeight = image.width / aspectRatio;
  } else {
    newWidth = image.height * aspectRatio;
    newHeight = image.height;
  }

  let x = 0;
  let y = 0;
  if (clipPosition === "left-top") {
    x = 0;
    y = 0;
  } else if (clipPosition === "left-middle") {
    x = 0;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "left-bottom") {
    x = 0;
    y = image.height - newHeight;
  } else if (clipPosition === "center-top") {
    x = (image.width - newWidth) / 2;
    y = 0;
  } else if (clipPosition === "center-middle") {
    x = (image.width - newWidth) / 2;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "center-bottom") {
    x = (image.width - newWidth) / 2;
    y = image.height - newHeight;
  } else if (clipPosition === "right-top") {
    x = image.width - newWidth;
    y = 0;
  } else if (clipPosition === "right-middle") {
    x = image.width - newWidth;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "right-bottom") {
    x = image.width - newWidth;
    y = image.height - newHeight;
  } else if (clipPosition === "scale") {
    x = 0;
    y = 0;
    newWidth = width;
    newHeight = height;
  } else {
    console.error(new Error("Unknown clip position property - " + clipPosition));
  }

  return {
    cropX: x,
    cropY: y,
    cropWidth: newWidth,
    cropHeight: newHeight,
  };
}

export function capitilizeString(string: string): string {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const defaultOpts = {
  retryLimit: 3,
  baseDelay: 1000, // base delay in milliseconds
  on: [Error],
};

function logRetryError(error: Error, attempts: number, retryLimit: number) {
  console.error(`Retry attempt ${attempts}/${retryLimit} failed: ${error.name} - ${error.message}`);
}

export async function withExponentialBackoff<T>(
  fn: () => Promise<T>,
  opts: { retryLimit?: number; baseDelay?: number; on?: any[] } = defaultOpts
): Promise<T> {
  const { retryLimit, baseDelay, on } = { ...defaultOpts, ...opts };

  let attempts = 0;

  while (attempts <= retryLimit) {
    try {
      return await fn();
    } catch (error) {
      if (!on.some((errType) => Object.getPrototypeOf(error) === errType.prototype)) {
        throw error;
      }

      logRetryError(error, attempts, retryLimit);
      attempts += 1;

      if (attempts > retryLimit) {
        throw error;
      }

      const sleepDurationInMs = baseDelay * Math.pow(2, attempts - 1);
      await new Promise((resolve) => setTimeout(resolve, sleepDurationInMs));
    }
  }

  throw new Error(`Function failed after ${retryLimit} retries`);
}
