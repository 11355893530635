<template>
  <Image
    :src="colorTileImage"
    alt="Colorpicker"
    img-classes="object-cover bg-white"
    class="float-left !h-20 !w-20"
  />
  <input
    type="color"
    v-model="color"
    class="absolute cursor-pointer !h-20 !w-20"
    @input="onColorInput"
    @focus="onColorComplete"
  />
</template>

<script setup lang="ts">
import colorTileImage from "@public/images/colorTile.png";
import { Image } from "prism";
import { ref } from "vue";
import debounce from "lodash.debounce";

const color = ref("#0000FF");

const emit = defineEmits<{
  (e: "colorPickerChanged", color: string): void;
  (e: "colorPickerChangeComplete", color: string): void;
}>();

// Emits the event continuously as the color changes
const onColorInput = () => {
  emit("colorPickerChanged", color.value);
  debouncedEmitColorComplete();
};

const onColorComplete = () => {
  emit("colorPickerChangeComplete", color.value);
};

const debouncedEmitColorComplete = debounce(() => {
  onColorComplete();
}, 300);

</script>

<style lang="css" scoped>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
</style>
