<template>
  <div
    :data-tippy-content="props.popoverCopy"
    data-tippy-placement="bottom"
    class="inline-flex rounded-full !bg-secondary px-3 py-2 absolute top-4 left-4 z-[3]"
  >
    <span class="text-typo-secondary text-xs hover-tooltip w-full">{{ I18n.t("image.preview") }}</span>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
const props = defineProps<{ popoverCopy: string }>();
</script>
