import { useEditorStore } from "@/stores/editor_store";
import { useCheckIOS } from "./check_if_ios";
import { computed } from "vue";
import semver from "semver";

// Below iOS18 there is a limit of 16MP for images to be downloaded
export const useCheckIfIOSImageTooLarge = () => {
  const store = useEditorStore();
  const IOS_MAX_DOWNLOAD_PIXELS = 16_777_216;
  const isIOS = useCheckIOS();

  return computed(() => {
    if (!isIOS || isIOSVersionOrGreater(18.0)) return false;
    const image = store.selectedImage;
    const totalPixels = image.meta.hdHeight * image.meta.hdWidth;

    return totalPixels > IOS_MAX_DOWNLOAD_PIXELS;
  });
};

export const getIOSVersion = (): string | null => {
  const isIOS = useCheckIOS();
  if (!isIOS) return null;

  const agent = navigator.userAgent;
  const match = agent.match(/(?:iPhone )?OS (\d+)_(\d+)(?:_(\d+))?/);
  if (!match) return null;

  return `${match[1]}.${match[2]}.${match[3] || "0"}`;
};

export const isIOSVersionOrGreater = (version: string | number): boolean => {
  const currentVersion = getIOSVersion();
  if (!currentVersion) return false;

  const compareVersion = typeof version === "number" ? `${version}.0.0` : version;

  return semver.gte(currentVersion, compareVersion);
};
