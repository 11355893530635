<template>
  <Dialog ref="dialog" :on-open="onOpen">
    <template #trigger>
      <ActionButton class="flex-col sm:flex-col md:flex-row">
        <template #icon>
          <CanvaIcon />
        </template>
        <template #title
          ><span class="text-3xs pt-1">{{ I18n.t(`editor.create_design`) }}</span></template
        >
      </ActionButton>
    </template>
    <template v-slot:content="{ closeDialog }">
      <div class="flex justify-center flex-col gap-y-6">
        <div class="text-center flex flex-col items-center">
          <Heading level="h4" class="mb-2 max-w-[20ch]">{{ I18n.t("canva_cta.common.design_anything") }}</Heading>
          <p class="text-typo text-sm !m-0">{{ I18n.t("canva_cta.common.design_anything_description") }}</p>
        </div>
        <div class="max-w-[90%] grid grid-cols-3 gap-6 m-auto">
          <div v-for="(composition, index) in compositions" :key="index">
            <Card class="!p-0 flex relative">
              <ImageComposition :layers="composition" class="bg-shape" />
            </Card>
          </div>
        </div>
        <div class="flex flex-col gap-y-2">
          <div class="text-center">
            <Button autofocus :loading="isLoading" size="lg" @click="() => handleClick(closeDialog)">{{
              I18n.t("canva_cta.common.create")
            }}</Button>
          </div>
          <p class="text-typo-secondary text-xs text-center mt-2 mb-1" v-html="tos_html"></p>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, inject } from "vue";
import { ActionButton, CanvaIcon, Dialog, Heading, ImageComposition, Button, Card } from "prism";
import { useCanvaLinkBuilder } from "@/composables/canva_link_builder";
import { Image, ProcessingState, ResultVariant, hasMagicBrushHdResult } from "@/modules/internal_api/image";
import { useEditorStore } from "@/stores/editor_store";
import Client from "@/modules/internal_api/client";
import { rbgRedirectToCanvaClickedV100, rbgRedirectToCanvaConfirmedV100 } from "kaleido-event-tracker";

export interface CanvaCtaProps {
  image: Image;
  stage: any;
  stageHd: any;
  resetZoom: () => void;
  refreshAllHdLayers: (image: Image) => Promise<void>;
  persistentStore: any;
  getDataURL: Function;
}
const props = defineProps<CanvaCtaProps>();

const isLoading = ref<boolean>(false);

const store = useEditorStore();
const flavor = document.querySelector("meta[property='canva-env']").getAttribute("content");
const { buildUrl } = useCanvaLinkBuilder(flavor);

const I18n = inject("I18n");
const tos_html = I18n.t("canva_cta.common.tos_link_html", { link: "https://www.canva.com/policies/terms-of-use/" });

const bgCta1 = "/images/canva-cta/bg_cta_1.jpg";
const bgCta2 = "/images/canva-cta/bg_cta_2.jpg";
const bgCta3 = "/images/canva-cta/bg_cta_3.jpg";

const placeHolderImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3gAAf8AAIMAXre2dwAAAABJRU5ErkJggg==";
const preview = ref<string>(placeHolderImage);
const variant = ref<ResultVariant>(ResultVariant.Preview);

const onOpen = async (): Promise<void> => {
  preview.value = placeHolderImage;
  props.resetZoom();
  await refreshPreview();
  rbgRedirectToCanvaClickedV100({ image_id: props.image.meta.id });
};

const refreshPreview = async (): Promise<void> => {
  return new Promise(async (resolve) => {
    const image = store.selectedImage;
    const isMagicBrushResult =
      props.persistentStore.previewVariant === ResultVariant.MagicBrushPreview ||
      props.persistentStore.hdVariant === ResultVariant.MagicBrushHd;
    let isHdAvailable = false;

    if (isMagicBrushResult) {
      const iterationId = props.persistentStore.magicBrushCache?.iterationId || image.meta.ai_brush_last_iteration;
      isHdAvailable = hasMagicBrushHdResult(image, iterationId);
      variant.value = isHdAvailable ? ResultVariant.MagicBrushHd : ResultVariant.MagicBrushPreview;
    } else {
      isHdAvailable = image.hdResult?.state === ProcessingState.Finished;
      variant.value = isHdAvailable ? ResultVariant.Hd : ResultVariant.Preview;
    }

    if (isHdAvailable) {
      await props.refreshAllHdLayers(image);
      preview.value = (await props.stageHd()).toDataURL({
        x: 0,
        y: 0,
        width: image.meta.hdWidth,
        height: image.meta.hdHeight,
      });
    } else {
      preview.value = props.getDataURL(image, props.stage.getStage());
    }

    resolve();
  });
};

const handleClick = async (closeDialog: () => void) => {
  rbgRedirectToCanvaConfirmedV100({ image_id: props.image.meta.id });
  isLoading.value = true;
  const image = store.selectedImage;
  const result = await Client.uploadToBucket(image, preview.value, variant.value);

  if (result.success) {
    const url = buildUrl(result.url, "ia");
    // Safari on iOS is blocking all window.open calls inside async functions
    setTimeout(() => {
      window.open(url, "_blank");
    });
    closeDialog();
  } else {
    console.error("Upload to bucket failed", result);
    closeDialog();
  }

  isLoading.value = false;
};

const defaultBackgroundPlacement = {
  top: "0",
  left: "0",
  width: "100%",
  height: "auto",
  rotation: 0,
};

const defaultVariantAPlacement = {
  position: "absolute",
  rotation: 0,
  left: "50%",
  top: "50%",
  width: "auto",
  height: "auto",
  maxWidth: "72px",
  maxHeight: "72px",
};

const compositions = computed(() => [
  [
    {
      src: bgCta1,
      alt: "First background",
      placement: defaultBackgroundPlacement,
    },
    {
      src: preview.value,
      alt: "Image with removed background",
      placement: {
        ...defaultVariantAPlacement,
      },
    },
  ],
  [
    {
      src: bgCta2,
      alt: "Second background",
      placement: defaultBackgroundPlacement,
    },
    {
      src: preview.value,
      alt: "Image with removed background",
      placement: {
        ...defaultVariantAPlacement,
      },
    },
  ],
  [
    {
      src: bgCta3,
      alt: "Third background",
      placement: defaultBackgroundPlacement,
    },
    {
      src: preview.value,
      alt: "Image with removed background",
      placement: {
        ...defaultVariantAPlacement,
      },
    },
  ],
]);
</script>
