// Composable for testing if curent user agent is iphone or ipad
export function useCheckIOS(): boolean {
  const ua = window.navigator.userAgent;
  const isiOS = !!ua.match(/iPhone/i);
  // This hack is specific for iPad, standalone property in navigator is only used by
  // webkit browsers and it indicates if app is run as PWA. Should not have any impact any other browser
  const isiPad = !!(
    ua.match(/(iPad)/) ||
    (window.navigator.platform === "MacIntel" &&
      typeof navigator.standalone !== "undefined" &&
      navigator.maxTouchPoints > 1)
  );
  return isiOS || isiPad;
}
