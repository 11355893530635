<template>
  <div ref="page" id="page" class="w-full h-full absolute top-0" style="user-select: none">
    <div class="flex flex-col justify-between h-full">
      <div>
        <!-- Navbar height-->
        <div id="mobile-navbar" :style="navbarStyles">
          <div class="navbar-height w-full h-36 sm:h-18 md:h-18 flex-none hidden md:block" id="nav-placeholder"></div>
          <Header
            @toggle-compare-before="toggleCompareBefore"
            @toggle-compare-after="toggleCompareAfter"
            :currentPersistentStore="currentPersistentStore"
          />
          <div id="flashes" class="relative z-20">
            <AlertBanner v-if="store.selectedImageId" />
          </div>
          <ImageNavigation />
        </div>
      </div>

      <template v-if="!isLoading">
        <EmptyState v-if="store.images.length === 0" />
        <Editor
          ref="editor"
          v-else
          @enqueueWithToken="enqueueWithToken"
          :currentPersistentStore="currentPersistentStore"
          :persistent-stores="persistentStores"
        />
        <Footer v-if="store.images.length > 0" />
      </template>

      <div
        v-if="store.openedPanel !== null"
        id="mobileDrawerSpace"
        class="md:hidden"
        :style="getMobileDrawerSpacerHeight(store.openedPanel)"
      ></div>
      <div v-else class="md:hidden" style="height: 78px"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, reactive, computed, provide, onBeforeMount, StyleValue } from "vue";
import { v4 as uuidv4 } from "uuid";

import Client from "@/modules/internal_api/client";

import Header from "./header.vue";
import EmptyState from "./empty.vue";
import Editor from "./editor.vue";
import ImageNavigation from "./image_navigation.vue";
import Footer from "./footer.vue";

import { getNestedKey, generatePreviewFromFile, generatePreviewFromUrl, PreviewImage } from "@/modules/utils";
import { Panel, useEditorStore } from "@/stores/editor_store";

import { Image, Upload, UploadState, ProcessingState, ResultVariant } from "@/modules/internal_api/image";
import { useUploadValidation } from "@/modules/internal_api/upload_validation";

import { PersistentStore, ShadowConfiguration, createPersistentStore } from "@/stores/persistent_store";
import {
  rbgImageProcessedV102,
  RbgImageProcessedEventV102,
  rbgUploadImageV103,
  rbgEditorApplyShadowV101,
} from "kaleido-event-tracker";

import emitter from "@/modules/event_bus";

import { rbgImageForegroundNotDetectedV101, rbgImageInitialResultDisplayedV100 } from "kaleido-event-tracker";
import AlertBanner from "@/components/prism/alert_banner.vue";

import { updateSnowplowExperimentConfigs } from "@/src/snowplow";

const store = useEditorStore();
const { validateFile } = useUploadValidation();

const previousImagesPromise = ref<Promise<Image[]>>();
const editor = ref<InstanceType<typeof Editor>>();
const page = ref<HTMLElement>();
const isLoading = ref<boolean>(true);

onBeforeMount(() => {
  const footer = document.querySelector("footer");
  if (footer) {
    footer.remove();
  }
});

onMounted(async () => {
  const [experiments, _] = await Promise.all([Client.editorExperiments(), loadPreviousImages()]);
  updateSnowplowExperimentConfigs(experiments);
  updateExperiments(experiments);

  isLoading.value = false;
  page.value.addEventListener("mouseup", (_event) => {
    editor.value?.handleMouseUp(_event);
  });
  page.value.addEventListener("mousemove", (event) => {
    editor.value?.handleMouseMove(event);
  });
  page.value.addEventListener("touchend", (_event) => {
    editor.value?.handleMouseUp(_event);
  });

  emitter.emit("newEditorPageMounted");

  const maintenanceModeBanner = document.querySelector("#maintenanceModeBanner");

  maintenanceModeBannerVisible.value = !!maintenanceModeBanner;
});

const maintenanceModeBannerVisible = ref(false);
emitter.on("maintenanceModeBannerVisibilityChanged", (visible) => {
  maintenanceModeBannerVisible.value = visible;
});

const navbarStyles = computed(() => {
  const maintenanceModeBanner = document.querySelector("#maintenanceModeBanner");

  if (maintenanceModeBanner) {
    return {
      "margin-top": maintenanceModeBannerVisible.value ? maintenanceModeBanner.clientHeight + "px" : "0",
    };
  }
});

const loadPreviousImages = async () => {
  previousImagesPromise.value = Client.allImages();
  const previousImages = await previousImagesPromise.value;
  store.setImages(previousImages);
  cleanupOldImages(previousImages);
  selectLastImage();
};

const selectLastImage = () => {
  const lastSelectedImageId = localStorage.getItem("lastSelectedImageId");
  if (lastSelectedImageId) {
    const lastSelectedImage = store.images.find((image) => image.meta.id === lastSelectedImageId);
    if (lastSelectedImage) {
      store.selectImage(lastSelectedImage);
    } else {
      localStorage.removeItem("lastSelectedImageId");
    }
  }
};

const cleanupOldImages = async (images) => {
  const localStorageKeys: Array<string> = images.map((image: Image) => `image-${image.meta.id}`);

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith("image-") && !localStorageKeys.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

const persistentStores = reactive(new Map<string, PersistentStore>());
const currentPersistentStore = computed((): PersistentStore => {
  const id = store.selectedImageId;

  if (id === null) {
    return null;
  }

  if (persistentStores.has(id)) {
    return persistentStores.get(id);
  } else {
    const storeDefinition = createPersistentStore(id);
    const newStore = storeDefinition();
    newStore.persist();
    persistentStores.set(id, newStore);
    return newStore;
  }
});
provide("currentPersistentStore", currentPersistentStore);

const getShadowConfig = (image: Image): ShadowConfiguration => {
  const shadowMap = new Map<string, ShadowConfiguration>([
    ["car", { isShadowLayerVisible: true, shadowOpacity: 90 }],
    ["car_part", { isShadowLayerVisible: false, shadowOpacity: 90 }],
    ["car_interior", { isShadowLayerVisible: false, shadowOpacity: 90 }],
    ["product", { isShadowLayerVisible: false, shadowOpacity: 50 }],
    ["person", { isShadowLayerVisible: false, shadowOpacity: 50 }],
    ["animal", { isShadowLayerVisible: false, shadowOpacity: 50 }],
    ["graphic", { isShadowLayerVisible: false, shadowOpacity: 50 }],
    ["transportation", { isShadowLayerVisible: false, shadowOpacity: 50 }],
    ["other", { isShadowLayerVisible: false, shadowOpacity: 50 }],
  ]);

  if (image?.meta?.foregroundType) {
    return shadowMap.get(image.meta.foregroundType);
  }

  return { isShadowLayerVisible: false, shadowOpacity: 50 };
};

const configureShadows = (image: Image, shadowConfig: ShadowConfiguration) => {
  const imageStore = persistentStores.get(image?.meta?.id);
  imageStore.setupShadow(shadowConfig);
  imageStore.persist();
};

const addedDelay = async (delay: number) => {
  await new Promise((resolve, _) => {
    setTimeout(resolve, delay);
  });
};

const timeouts: Map<string, NodeJS.Timeout> = new Map();
const poll = async (image: Image, variant: ResultVariant): Promise<Image> => {
  const stateKey: string = `${variant}.state`;
  const nextPollKey: string = `${variant}.nextFetchIn`;
  const timeoutKey: string = `${variant}.${image.meta.id}.timeout`;
  // console.log("Start polling for image", image.meta.id, "and variant", variant);
  const executePoll = async (image, resolve, reject): Promise<void> => {
    const updatedImage = await Client.getImage(image.meta.id);

    // The image has not finished processing yet, the server does not return
    // the preview width and height yet in this case
    // carry it over from the passed in image to prevent flickering in the editor
    if (updatedImage.meta.previewWidth === null || updatedImage.meta.previewHeight === null) {
      const { previewWidth, previewHeight } = image.meta;
      updatedImage.meta = { ...updatedImage.meta, previewWidth, previewHeight };
    }
    // The server does return a preview but we don't need to replace it on the poll,
    // makes for a smoother experience and prevents flickering in the image strip
    const { preview, livePreview, width, height, sizeMb } = image.meta;
    updatedImage.meta = { ...updatedImage.meta, preview, livePreview, width, height, sizeMb };

    store.updateImage(updatedImage);
    await nextTick();

    const state = getNestedKey(updatedImage, stateKey) as ProcessingState;
    const isFinished = [ProcessingState.Finished, ProcessingState.Error].includes(state);

    if (isFinished === true) {
      const eventUploadState = updatedImage.original.state === UploadState.Finished ? "success" : "error";
      rbgUploadImageV103({
        image_id: updatedImage.meta.id,
        editor_version: "inline_editor",
        upload_state: eventUploadState,
        image_width: updatedImage.meta.width,
        image_height: updatedImage.meta.height,
        image_size: updatedImage.meta.sizeMb,
      });

      if (state === ProcessingState.Finished) {
        let eventData: RbgImageProcessedEventV102 = {
          image_id: updatedImage.meta.id,
          foreground_type: updatedImage.meta.foregroundType,
          models_version: updatedImage.meta.modelsVersion,
          orientation: updatedImage.meta.orientation,
          preview_width: String(updatedImage.meta.previewWidth),
          preview_height: String(updatedImage.meta.previewHeight),
        };

        if (updatedImage.meta.fullAvailable === true) {
          eventData = {
            ...eventData,
            hd_width: String(updatedImage.meta.hdWidth),
            hd_height: String(updatedImage.meta.hdHeight),
          };
        }

        const shadowConfig = getShadowConfig(updatedImage);
        configureShadows(updatedImage, shadowConfig);

        if (shadowConfig.isShadowLayerVisible) {
          rbgEditorApplyShadowV101({
            image_id: updatedImage.meta.id,
            opacity_value: shadowConfig.shadowOpacity,
            default_shadow: true,
          });
        }
        rbgImageProcessedV102(eventData);
      }

      clearTimeout(timeouts.get(timeoutKey));
      timeouts.delete(timeoutKey);
      return resolve(updatedImage);
    } else {
      const timeout = setTimeout(async () => {
        executePoll(updatedImage, resolve, reject);
      }, getNestedKey(updatedImage, nextPollKey));

      timeouts.set(timeoutKey, timeout);
    }
  };

  return new Promise((resolve, reject) => {
    executePoll(image, resolve, reject);
  });
};

const enqueue = async (upload: Upload, preview: PreviewImage) => {
  // Create a new temporary image
  const image: Image = {
    meta: {
      id: `temp-${uuidv4()}`,
      self: "/",
      preview: preview.image,
      livePreview: preview.image,
      previewHeight: preview.height,
      previewWidth: preview.width,
      width: preview.original.width,
      height: preview.original.height,
      ai_brush_processing_full_count: 0,
      ai_brush_iterations: null,
      ai_brush_last_iteration: null,
      sizeMb: preview.original.sizeMb,
    },
    original: upload,
    previewResult: {
      state: ProcessingState.Processing,
      orderedAt: new Date(),
      nextFetchIn: 0,
    },
  };
  // Make sure the previous images are loaded,
  // otherwise adding the new image will mess up the order
  await previousImagesPromise.value;
  editor.value?.hideResult(image);
  await nextTick();

  store.addImage(image);
  store.selectImage(image);

  await nextTick();
  const interval = setInterval(() => {
    setTimeout(() => clearInterval(interval), 2000);
    if (editor.value) {
      editor.value.trustComponent.requestToken(image);
      clearInterval(interval);
    }
  }, 50);
};

const enqueueWithToken = async (image) => {
  // console.time("uploadImage");
  const start = performance.now();
  const uploadedImage = await Client.uploadImage(image);
  // console.timeEnd("uploadImage");
  store.replaceImage(image, uploadedImage);
  store.selectImage(uploadedImage);

  if (uploadedImage.original.state === UploadState.Error) {
    return;
  }

  // console.time("sdProcessing");
  const updatedImage = await poll(uploadedImage, ResultVariant.Preview);
  // console.timeEnd("sdProcessing");

  if (updatedImage.meta.has_no_foreground === true) {
    rbgImageForegroundNotDetectedV101({
      image_id: updatedImage.meta.id,
      models_version: updatedImage.meta.modelsVersion,
    });
  }

  // On slow networks this layer refresh results in a race condition with
  // the the one automatically triggered by the onAction trigger on selectImage
  // therefore we need to force the refresh here to ensure that the result will be visible
  await editor.value.refreshAllLayers(true);
  const end = performance.now();
  rbgImageInitialResultDisplayedV100({ image_id: updatedImage.meta.id, processing_time_ms: end - start });

  editor.value.revealResult();
};

const filePicked = async (file: File) => {
  let validationResult = validateFile(file);

  if (!validationResult.valid) {
    // TODO: Use ImageResizer
    // TODO: use validateFile again
    // Show error if this was not enough
  }

  const preview: PreviewImage = await generatePreviewFromFile(file);
  const upload: Upload = {
    state: UploadState.Pending,
    file: file,
    fileName: file.name,
    preview: preview.image,
  };

  await enqueue(upload, preview);
};

const filePickedBase64 = async (filename: string, mimetype: string, base64: string) => {
  const response = await fetch(`data:${mimetype};base64,${base64}`);
  const blob = await response.blob();

  const file = new File([blob], filename, { type: mimetype });
  await filePicked(file);
};

const urlPicked = async (url: string) => {
  const preview: PreviewImage = await generatePreviewFromUrl(url);
  const upload: Upload = {
    state: UploadState.Pending,
    url: url,
    preview: preview.image,
  };

  await enqueue(upload, preview);
};

const getMobileDrawerSpacerHeight = (panel: Panel): StyleValue => {
  if (panel === "eraseRestore") {
    return { height: "11rem" };
  } else if (panel == "addBackground") {
    return { height: "20rem" };
  } else {
    return { height: "15rem" };
  }
};

type Example = { url: string; thumbnail_url: string };
const examplePicked = async (example: Example) => {
  const preview: PreviewImage = await generatePreviewFromUrl(example.url);
  const upload: Upload = {
    state: UploadState.Pending,
    url: example.url,
    preview: preview.image,
  };

  await enqueue(upload, preview);
  window.track("Images", "upload_sample_image", "Sample Image");
};

const toggleCompareBefore = () => {
  editor.value?.toggleCompareBefore();
};

const toggleCompareAfter = () => {
  editor.value?.toggleCompareAfter();
};

interface EditorExperiments {
  experiment_name: string;
  experiment_variant: string;
}
const updateExperiments = (editor_experiments: Array<EditorExperiments>) => {
  const experimentsTag = document.querySelector('meta[name="experiments"]');

  const exps = editor_experiments.reduce((acc, item) => {
    acc[item["experiment_name"]] = item["experiment_variant"];
    return acc;
  }, {});

  const parsedExperiments = JSON.parse(experimentsTag?.getAttribute("content") || "{}");
  const newExperiments = { ...parsedExperiments, ...exps };
  experimentsTag.setAttribute("content", JSON.stringify(newExperiments));
};

defineExpose({
  filePicked,
  urlPicked,
  examplePicked,
  filePickedBase64,
});
</script>
<style scoped>
.navbar-height {
  @media (min-width: 640px) and (max-width: 768px) {
    & {
      height: 9.2rem !important;
    }
  }
}

#mobile-navbar {
  transition: margin-top 0.5s;
}

@media (max-width: 345px) {
  #flashes {
    padding-top: 2.5rem;
  }
}
</style>
