<template v-if="props.selectedImageState?.photoList">
  <template v-if="!props.isSearchActive">
    <template>
      <!-- Loading icon tile when background is being uploaded -->
      <ImageButton
        v-if="store.isUploadingCustomBackground"
        :size="props.imageButtonSize"
        :selected="true"
        :elevate-on-hover="false"
        :small-on-mobile="false"
        class="justify-center items-center"
      >
        <div class="bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full">
          <LoadingIcon class="text-white animate-spin" />
        </div>
      </ImageButton>

      <!-- Uploaded Background tile -->
      <ImageButton
        :size="props.imageButtonSize"
        v-else-if="props.selectedImageState?.uploadedBackgroundPhotoUrl"
        :elevate-on-hover="false"
        :small-on-mobile="false"
        :selected="isSelected(props.selectedImageState?.uploadedBackgroundPhotoUrl)"
        @click.prevent="
          isSelected(props.selectedImageState?.uploadedBackgroundPhotoUrl)
            ? deselect({ withDelete: true })
            : select(createPlainBackgroundObjectFromUrl(props.selectedImageState?.uploadedBackgroundPhotoUrl))
        "
        :key="props.selectedImageState?.uploadedBackgroundPhotoUrl"
        class="justify-center items-center"
      >
        <Image
          :src="props.selectedImageState?.uploadedBackgroundPhotoUrl"
          alt="Uploaded image"
          :img-classes="`object-cover ${imageDimensionClasses} bg-white checkerboard`"
        />
        <template #action>
          <MinusIcon />
        </template>
      </ImageButton>

      <!--  Add Background Icon tile-->
      <ImageButton
        v-else
        :small-on-mobile="false"
        :elevate-on-hover="false"
        @click="uploadFile"
        :size="props.imageButtonSize"
        class="justify-center items-center"
      >
        <div class="!bg-white !hover:bg-secondary h-full flex justify-center items-center">
          <AddIcon />
        </div>
      </ImageButton>
    </template>

    <!--  Selected Background Image tile-->
    <ImageButton
      v-if="props.selectedImageState?.lastSelectedBackgroundPhoto"
      :elevate-on-hover="false"
      :small-on-mobile="false"
      :selected="isSelected(props.selectedImageState.lastSelectedBackgroundPhoto.image_url)"
      @click.prevent="
        toggleSelect(
          createPlainBackgroundObjectFromUrl(props.selectedImageState.lastSelectedBackgroundPhoto.image_url),
          isSelected(props.selectedImageState.lastSelectedBackgroundPhoto.image_url)
        )
      "
      :key="props.selectedImageState.lastSelectedBackgroundPhoto.image_url"
      size="lg"
      class="justify-center items-center"
    >
      <Image
        :src="props.selectedImageState.lastSelectedBackgroundPhoto.image_url"
        alt=""
        img-classes="object-cover w-20 h-20 bg-white checkerboard"
      />
      <template #action>
        <MinusIcon />
      </template>
    </ImageButton>

    <!-- original background -->
    <ImageButton
      :size="props.imageButtonSize"
      :elevate-on-hover="false"
      :small-on-mobile="false"
      :selected="isSelected(originalUrl(store.selectedImage))"
      @click.prevent="toggleSelect(
              createPlainBackgroundObjectFromUrl(originalUrl(store.selectedImage)),
              isSelected(originalUrl(store.selectedImage))
            )"
      :key="originalUrl(store.selectedImage)"
      class="justify-center items-center"
    >
      <Image
        :src="originalUrl(store.selectedImage)"
        alt="Original image"
        :img-classes="`object-cover ${imageDimensionClasses} bg-white checkerboard`"
      />
      <template #action>
        <MinusIcon />
      </template>
    </ImageButton>
  </template>

  <template v-for="(photo, i) in props.availableBackgrounds">
    <TransitionGroup name="fade" tag="div" class="inline">
      <ImageButton
        :size="props.imageButtonSize"
        :elevate-on-hover="false"
        :small-on-mobile="false"
        :selected="isSelected(photo.image_url)"
        @click.prevent="toggleSelect(photo, isSelected(photo.image_url))"
        :key="photo.thumbnail_url"
        class="justify-center items-center"
      >
        <Image
          :src="photo.thumbnail_url"
          alt="Example image"
          :img-classes="['object-cover checkerboard', imageDimensionClasses]"
          @imgLoaded="() => setImageLoaded(photo.image_url)"
        />
        <div
          v-if="isSelectedImageLoading(photo.image_url)"
          class="top-0 bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full"
        >
          <LoadingIcon class="text-white animate-spin" />
        </div>
        <template #action>
          <MinusIcon />
        </template>
      </ImageButton>
    </TransitionGroup>
  </template>
  <template v-if="props.isLoading">
    <LoadingPlaceholder :amount-of-tiles="32" :additional-classes="imageDimensionClasses" />
  </template>
</template>

<script setup lang="ts">
import { Image, ImageButton, AddIcon, LoadingIcon, MinusIcon, TrashIcon } from "prism";
import {
  Background,
  originalUrl,
  createPlainBackgroundObjectFromUrl,
  BackgroundImageOrigin,
} from "@/modules/internal_api/image";
import { useEditorStore } from "@/stores/editor_store";
import {
  rbgEditorApplyBackgroundImageV102,
  rbgEditorUnapplyBackgroundImageV100,
} from "kaleido-event-tracker";
import { PersistentStore } from "@/stores/persistent_store";
import { ref } from "vue";
import LoadingPlaceholder from "./photo_tiles_loading_placeholder.vue";

export interface PhotoTilesProps {
  isLoading: boolean;
  selectedImageState: PersistentStore;
  availableBackgrounds: Background[];
  imageButtonSize: string;

  // This is temporary, solution - it will be done when applying the background image will be finalized.
  // TODO: we can update this `isSearchActive` props to `showPhotoTiles`
  //   and have on v-if at the outer wrapper instead of doing it one element at a time
  isSearchActive: boolean;
}

const props = withDefaults(defineProps<PhotoTilesProps>(), {
  imageButtonSize: "default",
});

const store = useEditorStore();

const select = (photo: Background) => {
  if (store.isApplyingBackgroundChanges) return;

  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setSelectedBackgroundColor(undefined);
    props.selectedImageState.setSelectedBackgroundPhoto(photo);
    props.selectedImageState.setIsBackgroundAddedByBlur(false);
    props.selectedImageState.setSelectedSearchedBackground(props.isSearchActive);
  });

  rbgEditorApplyBackgroundImageV102({
    image_id: store.selectedImage.meta.id,
    background_image_id: photo.image_url,
    source: backgroundImageSource(),
  });
};

const deselect = ({ withDelete }: { withDelete: boolean }) => {
  if (store.isApplyingBackgroundChanges) return;

  rbgEditorUnapplyBackgroundImageV100({
    image_id: store.selectedImage.meta.id,
    background_image_id: props.selectedImageState.selectedBackgroundPhotoUrl,
    source: backgroundImageSource(),
  });

  props.selectedImageState.withSnapshot(() => {
    if (withDelete) {
      props.selectedImageState.setUploadedBackgroundPhotoUrl(undefined);
    }
    props.selectedImageState.setSelectedBackgroundColor(undefined);
    props.selectedImageState.setSelectedBackgroundPhoto(undefined);
    props.selectedImageState.setSelectedSearchedBackground(false);
  });
};

const backgroundImageSource = () => {
  if (isSelected(props.selectedImageState.uploadedBackgroundPhotoUrl)) {
    return BackgroundImageOrigin.User;
  }
  if (isSelected(props.selectedImageState.lastSelectedBackgroundPhoto?.image_url)) {
    return props.selectedImageState.lastSelectedBackgroundPhoto.image_origin;
  }
  return BackgroundImageOrigin.Preset;
};

const toggleSelect = (photo: Background, isSelected: boolean) => {
  if (store.isApplyingBackgroundChanges) return;
  isSelected ? deselect({ withDelete: false }) : select(photo);
};

const isSelected = (image_url) => {
  return props.selectedImageState.selectedBackgroundPhotoUrl === image_url;
};

const imageDimensionClasses = "!h-20 !w-20";

const isSelectedImageLoading = (image_url: string) =>
  isSelected(image_url) && props.selectedImageState.selectedBackgroundLoading;

const uploadFile = () => {
  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setSelectedSearchedBackground(false);
  });
  window.onUploadBackgroundFile();
};

const imageLoaded = ref({});

const setImageLoaded = (id) => {
  if (imageLoaded.value[id]) return;

  imageLoaded.value = {
    ...imageLoaded.value,
    [id]: true,
  };
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
